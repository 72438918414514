const ProfileDate = {
  level1: [
    {
      name: "FMCG",
      icon: "1.svg",
    },
    { name: "B&M", icon: "2.svg" },
    {
      name: "E-Commerce",
      icon: "2.svg",
    },
    {
      name: "Agro",
      icon: "4.svg",
    },
    {
      name: "CPGs",
      icon: "5.svg",
    },
    {
      name: "Pharmaceutical",
      icon: "6.svg",
    },
    {
      name: "F&B",
      icon: "7.svg",
    },

    {
      name: "Retail",
      icon: "8.svg",
    },
    {
      name: "INGOs",
      icon: "9.svg",
    },
    {
      name: "BOPs",
      icon: "10.svg",
    },
    {
      name: "Agency",
      icon: "11.svg",
    },
    {
      name: "Consulting",
      icon: "12.svg",
    },
  ],
};

export default ProfileDate;
