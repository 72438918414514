import AboutHero from "./AboutHero";
import AllStarted from "./AllStarted";
import OurCoreValues from "./OurCoreValues";

const AboutUs = () => {
  return (
    <div>
      <AboutHero />
      <AllStarted />
      <OurCoreValues />
    </div>
  );
};

export default AboutUs;
