import { Col, Container, Row } from "react-bootstrap";
import mobileImage from "../img/liveShare.png";
const LiveShare = () => {
  return (
    <div className="palning-main-area live-share-area" id="liveShare">
      <Container>
        <div className="palning-inner-area-wrap live-share-inner">
          <Row>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item LiveShare">
                <h4>100% live, Automated</h4>
                <p>100% live, automated data panel for your next planning.</p>
                <button className="btn feature-left-btn  palning">
                  <a
                    href="https://maacsolutions.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Request for Demo
                  </a>
                </button>
              </div>
            </Col>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item image-feature-mobile image-bouns-animation">
                <img src={mobileImage} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LiveShare;
