const MarchXIcon = () => {
  return (
    <svg
      width="474"
      height="495"
      viewBox="0 0 474 495"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.535 285.065L199.53 254.322L204.183 248.361L200.469 241.571L184.278 211.932C178.895 202.045 173.407 192.201 167.961 182.357C162.515 172.512 157.049 162.668 151.499 152.866C149.016 148.474 146.554 144.083 144.071 139.67L147.701 139.606L170.048 139.16L192.394 138.63L209.817 138.142L223.296 160.483L239.842 187.47L244.578 195.214L250.316 188.997C256.617 182.166 262.981 175.377 269.262 168.503C275.563 161.65 281.822 154.776 288.061 147.859L306.673 127.025C309.782 123.567 312.828 120.023 315.895 116.523C318.962 113.022 322.03 109.5 325.034 105.936C321.445 108.885 317.877 111.898 314.33 114.889C310.783 117.881 307.215 120.872 303.71 123.927L282.636 142.173C275.646 148.283 268.677 154.436 261.75 160.61C257.035 164.79 252.34 168.991 247.666 173.213L237.129 152.484L222.565 124.352L220.249 119.896L214.762 119.748L192.415 119.111L170.069 118.581L147.722 118.135L125.375 117.775L106.993 117.477L115.84 134.281C121.098 144.252 126.502 154.118 131.823 164.047C137.143 173.976 142.548 183.863 147.952 193.729C153.356 203.615 158.718 213.502 164.206 223.325L176.933 246.26L158.134 271.317L134.911 302.674C119.533 323.657 104.197 344.682 89.1118 365.899C73.9846 387.072 59.0243 408.395 44.2726 429.887C36.8864 440.622 29.5627 451.4 22.3433 462.263C15.0822 473.083 7.90461 483.988 0.873047 494.999C9.65728 485.367 18.2746 475.608 26.8085 465.785C35.4049 456.004 43.8762 446.138 52.3057 436.23C69.1648 416.414 85.8152 396.45 102.299 376.316C118.803 356.224 135.099 335.962 151.311 315.637L175.535 285.065Z"
        fill="#023D98"
      />
      <path
        d="M447.418 29.1724C438.821 38.9531 430.35 48.8186 421.921 58.7266C405.062 78.5426 388.411 98.5071 371.928 118.641C355.423 138.733 339.128 158.995 322.915 179.32L298.712 209.914L274.717 240.656L270.064 246.618L273.778 253.407L289.969 283.046C295.353 292.933 300.84 302.777 306.286 312.622C311.732 322.466 317.198 332.31 322.748 342.112C325.231 346.504 327.693 350.896 330.176 355.309L326.546 355.372L304.199 355.818L281.853 356.348L264.43 356.836L250.951 334.496L234.405 307.508L229.669 299.764L223.931 305.981C217.63 312.813 211.266 319.602 204.985 326.476C198.684 333.329 192.425 340.203 186.186 347.119L167.574 367.954C164.465 371.412 161.419 374.955 158.352 378.456C155.285 381.956 152.217 385.478 149.213 389.043C152.802 386.093 156.37 383.081 159.917 380.089C163.464 377.098 167.032 374.106 170.537 371.051L191.611 352.805C198.601 346.695 205.57 340.542 212.497 334.368C217.212 330.189 221.907 325.988 226.581 321.766L237.118 342.494L251.682 370.627L253.998 375.082L259.485 375.231L281.832 375.867L304.178 376.398L326.525 376.843L348.872 377.204L367.254 377.501L358.407 360.698C353.149 350.726 347.745 340.86 342.424 330.931C337.104 321.002 331.7 311.115 326.296 301.25C320.891 291.363 315.529 281.476 310.042 271.653L297.314 248.739L316.113 223.683L339.336 192.325C354.714 171.343 370.05 150.317 385.135 129.101C400.263 107.927 415.223 86.6048 429.975 65.1127C437.361 54.3773 444.684 43.5994 451.904 32.7367C459.165 21.9164 466.342 11.0112 473.374 0C464.569 9.58975 455.952 19.3492 447.418 29.1724Z"
        fill="#009B9B"
      />
    </svg>
  );
};

export default MarchXIcon;
