import { Container } from "react-bootstrap";
import icon1 from "./Img/1.svg";
import icon2 from "./Img/2.svg";
import icon3 from "./Img/3.svg";
import icon4 from "./Img/4.svg";
import icon5 from "./Img/5.svg";
const Service = () => {
  return (
    <div className="service-main-area">
      <Container>
        <div className="service-inner-wrap">
          <h3>Key Features</h3>
          <div className="service-items">
            <div className="service-single-item">
              <img src={icon1} alt="" />
              <h6>Attandance</h6>
            </div>{" "}
            <div className="service-single-item">
              <img src={icon2} alt="" />
              <h6>Route Plan</h6>
            </div>{" "}
            <div className="service-single-item">
              <img src={icon3} alt="" />
              <h6>Competition Survey</h6>
            </div>{" "}
            <div className="service-single-item">
              <img src={icon4} alt="" />
              <h6>NPIs</h6>
            </div>{" "}
            <div className="service-single-item">
              <img src={icon5} alt="" />
              <h6>Campaign Efficiency</h6>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Service;
