import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Header from "./Common/Header";
import MainFooter from "./footer/MainFooter";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactForm from "./Pages/ContactUs/ContactForm";
import MerchXHome from "./Pages/MerchXHome/MerchXHome";
import PricingContent from "./Pages/Pricing/PricingContent";
import ScrollToTop from "./ScrollToTop";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/about-us">
          <Header />
          <AboutUs />
          <MainFooter />
        </Route>{" "}
        <Route exact path="/contact">
          <Header />
          <ContactForm />
          <MainFooter />
        </Route>{" "}
        <Route exact path="/pricing">
          <Header />
          <PricingContent />
          <MainFooter />
        </Route>
        <Route exact path="/">
          <Header />
          <MerchXHome />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
