import { Container, Table } from "react-bootstrap";
import CheckIcon from "./icon/CheckIcon";
import ShieldIcon from "./icon/ShieldIcon";

const PricingContent = () => {
  return (
    <div className="pricing-main-area-wrap">
      <Container>
        <div className="pricing-inner-area-wrap">
          <div className="pricing-top-area-title">
            <h4>Transparent Pricing</h4>
            <p>Pricing built for people just like you.</p>
          </div>
          <div className="pricing-items-main-wrap">
            <div className="pricing-content-area-wrap">
              <div className="pricing-content-area-item">
                <div>
                  <ShieldIcon />
                  <h5>30-day money-back guarantee</h5>
                </div>
              </div>
            </div>{" "}
            <div className="pricing-content-area-wrap">
              <div className="pricing-content-area-item">
                <div>
                  <h6>Starter</h6>
                  <h1>$0</h1>
                  <span>per montd</span>
                </div>
                <a href="!#">Choose plan</a>
              </div>
            </div>{" "}
            <div className="pricing-content-area-wrap recommended">
              <div className="pricing-content-area-item">
                <div>
                  <h6>Starter</h6>
                  <h1>$0</h1>
                  <span>per montd</span>
                </div>
                <a href="!#">Choose plan</a>
              </div>
            </div>{" "}
            <div className="pricing-content-area-wrap">
              <div className="pricing-content-area-item">
                <div>
                  <h6>Starter</h6>
                  <h1>$0</h1>
                  <span>per montd</span>
                </div>
                <a href="!#">Choose plan</a>
              </div>
            </div>
          </div>{" "}
          <div className="pricing-items-main-wrap pricing-table">
            <Table striped borderless hover responsive>
              <thead className="mobile-view-table-date">
                <tr>
                  <th>Featured Price</th>
                  <th>Featured Price</th>
                  <th>Featured Price</th>
                </tr>
              </thead>
              <tbody className="mobile-view-table-date">
                <tr>
                  <td>Feature title</td>
                  <td></td>
                  <td>
                    <CheckIcon />
                  </td>
                  <td>
                    <CheckIcon />
                  </td>
                </tr>{" "}
                <tr>
                  <td>Feature title</td>
                  <td>
                    {" "}
                    <CheckIcon />
                  </td>
                  <td>
                    <CheckIcon />
                  </td>
                  <td>Username</td>
                </tr>{" "}
                <tr>
                  <td>Feature title</td>
                  <td>
                    {" "}
                    <CheckIcon />
                  </td>
                  <td>
                    <CheckIcon />
                  </td>
                  <td>
                    {" "}
                    <CheckIcon />
                  </td>
                </tr>
              </tbody>
              {/* <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>tdornton</td>
                  <td>@fat</td>
                </tr>
              </tbody> */}
            </Table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PricingContent;
