import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ProfileDate from "./InsurtrirsData";

// import "slick-carousel/slick/slick-theme.css";
const Industries = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    // slidesToScroll: 1,
    autoplay: true,
    margin: 10,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div className="industries-main-wrap" id="industries">
      <Container>
        <div className="section-title text-center">
          <h2>Industries</h2>
        </div>
        <Slider {...settings}>
          {ProfileDate.level1.map((item, i) => (
            <div key={i} className="industries-single-item">
              <div className="industries-single-item-inner">
                <div>
                  <span>
                    {console.log(item.icon)}
                    <img src={require(`./img/${item.icon}`)} alt="icon" />
                  </span>
                  <h5>{item.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Industries;
