import { Col, Container, Row } from "react-bootstrap";
import mobileImage from "./liveShare.png";
const AboutHero = () => {
  return (
    <div className="palning-main-area live-share-area" id="liveShare">
      <Container>
        <div className="palning-inner-area-wrap live-share-inner">
          <Row>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item LiveShare">
                <h4>Interactive Dashboard</h4>
                <p>
                  Precisely, a data extraction tool offers multidimensional
                  enterprise support in relation to data capturing, data
                  curating, GEO tagging, and finally descending to a dynamic
                  data vs. utilization. First-of-its-kind in Bangladesh as such
                  available both in apps and web services. Seriously, the sky is
                  not even.
                </p>
                <button className="btn feature-left-btn  palning">
                  <a
                    href="https://maacsolutions.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Request for Demo
                  </a>
                </button>
              </div>
            </Col>
            <Col lg={6} className="my-auto">
              <div className="feature-inner-single-item image-feature-mobile image-bouns-animation">
                <img src={mobileImage} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AboutHero;
