const CheckIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6242 0.00771476 23.9923 5.37578 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12.082 17.489L18.927 8.2C19.1584 7.91359 19.2137 7.52318 19.0709 7.18377C18.9281 6.84437 18.6104 6.61089 18.2438 6.57604C17.8773 6.54118 17.5212 6.71059 17.317 7.017L11.09 15.468L7.014 12.207C6.58267 11.8618 5.95318 11.9317 5.608 12.363C5.26282 12.7943 5.33267 13.4238 5.764 13.769L10.652 17.677C10.8657 17.844 11.1375 17.9182 11.4064 17.8829C11.6753 17.8475 11.9188 17.7056 12.082 17.489Z"
          fill="#009B9B"
        />
      </svg>
    </div>
  );
};

export default CheckIcon;
