import { Col, Container, Row } from "react-bootstrap";
import heroLogo from "../../../Common/img/SalesX.svg";
import heroImage from "../img/heroRight.png";
import heroImageBrandIcon from "../img/heroRightBrand.svg";
const Hero = () => {
  return (
    <div className="hero-container-main-wrap" id="hero">
      <div className="fieldx-hero-inner-wrap">
        <Container>
          <div className="hero-inner-wrap">
            <div
              style={{ backgroundImage: `url(${heroImageBrandIcon})` }}
              className="hero-brand-icon-right"
            />
            <div className="hero-top-content-wrap-fieldx">
              <Row>
                <Col lg={12} className="my-auto">
                  <div className="hero-item-left-content">
                    <h3>
                      <img src={heroLogo} alt="" />
                    </h3>
                    <p>
                      Precisely, a data extraction tool offers multidimensional
                      enterprise support in relation to data capturing, data
                      curating, GEO tagging, and finally descending to a dynamic
                      data vs. utilization. First-of-its-kind in Bangladesh as
                      such available both in apps and web services. Seriously,
                      the sky is not even a limit here, especially for research
                      companies, INGOs, Activation agencies – all set to fly at
                      a-go.
                    </p>

                    <div className="hero-main-area-button">
                      <button className=" btn feature-left-btn  hero btn--swap ">
                        <div>
                          <span className="hero-button-area-left-1">
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                          <span
                            aria-hidden="true"
                            className="hero-button-area-left-2"
                          >
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                        </div>
                      </button>
                      {/* <button className=" btn feature-left-btn  hero btn--swap play-store-button">
                        <div>
                          <span className="playstore">
                            {" "}
                            <span>
                              <i className="bx bxl-play-store"></i>
                            </span>
                            Download{" "}
                          </span>
                          <span aria-hidden="true">
                            {" "}
                            <i className="bx bxl-play-store"></i> Download{" "}
                          </span>
                        </div>
                      </button> */}
                    </div>
                    {/* <div className="review-area-hero">
                      <span>
                        <i className="bx bxs-star"></i>
                        <span>4.8/5 based on 25+ ratings</span>
                      </span>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12} className="my-auto">
                <div className="hero-footer-image-wrap-fieldx-">
                  <Row>
                    <Col lg={8} className="my-auto">
                      <div className="hero-item-right-content image-bouns-animation">
                        <img src={heroImage} alt="" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
