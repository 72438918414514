import { Col, Container, Form, Row } from "react-bootstrap";
import contactRight from "./Illustration.png";
const ContactForm = () => {
  return (
    <div className="contact-form-main-area-wrap">
      <Container>
        <div className="contact-form-inner-area-wrap">
          <Row>
            <Col lg={6} className="my-auto">
              <div className="">
                <h1>Contact Us</h1>
                <Form>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Samsad Rashid" />
                  </Form.Group>{" "}
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type="email" placeholder="samsad@maac.com" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>subject</Form.Label>
                    <Form.Control type="text" placeholder="Enter email" />
                  </Form.Group>{" "}
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type your Message"
                    />
                  </Form.Group>
                  <button className="contact-form-submit-btn">
                    Send Messege
                  </button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="my-auto mb-3">
              <div className="">
                <img src={contactRight} className="img-fluid" alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
