import { Col, Container, Row } from "react-bootstrap";
import coreIcon2 from "./icon/Icon-fill.svg";
import coreIcon3 from "./icon/Profile.svg";
import coreIcon from "./icon/Trending.svg";
const OurCoreValues = () => {
  return (
    <div className="palning-main-area live-share-area" id="liveShare">
      <Container>
        <div className="palning-inner-area-wrap live-share-inner core-our-value-inner-wrap">
          <div className="our-values-title">
            <h4>Our Core Values</h4>
          </div>
          <Row>
            <Col lg={4} className="my-auto">
              <div className="feature-inner-single-item our-core-values-single">
                <img src={coreIcon} alt="" />
                <h6>Great Innovation</h6>
                <p>
                  We are always focusing on making all our products as
                  innovative as possible.
                </p>
              </div>
            </Col>
            <Col lg={4} className="my-auto">
              <div className="feature-inner-single-item our-core-values-single">
                <img src={coreIcon2} alt="" />
                <h6>High Quality</h6>
                <p>
                  One of our main values is the quality of the products that we
                  sell to the customers.
                </p>
              </div>
            </Col>
            <Col lg={4} className="my-auto">
              <div className="feature-inner-single-item our-core-values-single">
                <img src={coreIcon3} alt="" />
                <h6>Teamwork Matters</h6>
                <p>
                  We believe that being a successful company is all about being
                  a team.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default OurCoreValues;
