const ShieldIcon = () => {
  return (
    <>
      <svg
        width="30"
        height="36"
        viewBox="0 0 30 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.3729 0H27.6269C28.8643 0.00742433 29.8634 1.01261 29.8634 2.25V11.6445C29.8984 21.7983 24.3136 31.1383 15.3524 35.913C15.2438 35.9704 15.1228 36.0003 14.9999 36C14.8774 36 14.7568 35.9696 14.6489 35.9115C5.68639 31.1388 0.100613 21.7985 0.136401 11.6445V2.25C0.136379 1.01261 1.13554 0.00742433 2.3729 0ZM13.1997 21.9675L23.8497 11.9175C24.2393 11.549 24.4028 11.0007 24.2785 10.4791C24.1543 9.95751 23.7611 9.54182 23.2473 9.38863C22.7334 9.23544 22.1768 9.36804 21.7872 9.73647L12.1947 18.777L9.67175 16.227C9.29697 15.8356 8.74026 15.6768 8.21543 15.8116C7.69059 15.9463 7.27922 16.3536 7.13929 16.8771C6.99937 17.4006 7.15264 17.9588 7.54025 18.3375L11.0997 21.9315C11.6732 22.5133 12.6067 22.5293 13.1997 21.9675Z"
          fill="#009B9B"
        />
      </svg>
    </>
  );
};

export default ShieldIcon;
