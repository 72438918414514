import { Col, Container, Row } from "react-bootstrap";
import MarchXIcon from "./MarchXIcon";
const AllStarted = () => {
  return (
    <div className="palning-main-area live-share-area   merchx-about-page">
      <Container>
        <div className="palning-inner-area-wrap merchx-about-page">
          <Row>
            <Col lg={5} className="my-auto">
              <div className="feature-inner-single-item merchx-about-page-left">
                <h4>How and When it has All Started</h4>
                <MarchXIcon />
              </div>
            </Col>
            <Col lg={7} className="my-auto">
              <div className="feature-inner-single-item">
                <div className="merchx-about-right-single-item">
                  <h4>Natural Ingredients Only</h4>
                  <p>
                    10 years ago, when one of the co-founders came up with the
                    idea of making skincare and beauty products using only
                    natural ingredients, he did not even think twice.
                  </p>
                </div>{" "}
                <div className="merchx-about-right-single-item">
                  <h4>Natural Ingredients Only</h4>
                  <p>
                    10 years ago, when one of the co-founders came up with the
                    idea of making skincare and beauty products using only
                    natural ingredients, he did not even think twice.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AllStarted;
