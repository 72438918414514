import React,{ useState } from "react";
import "./callToAction.css";
import { useHistory } from "react-router-dom";
const axios = require("axios");
const CallToAction1 = () => {
  const history = useHistory();
  const [email, setemail] = useState("");
  const API_URL = "https://api-ieltsx.salesx-staging.xyz/api/v1/maacroute/newsletter";
  const axiosemailcheck = async (data, options) => {
    try {
      const response = await axios.post(API_URL, data);
      console.log(response.data);
      if (response.data.status) {
        // localStorage.setItem("testObject", JSON.stringify(data));
        // history.push("/maacgift/join-us");
        alert('your request is submitted')
        history.replace('/')
      } else {
        alert(response.data.message);
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  function handlesubmit(e) {
    e.preventDefault();
  
    let obj = {
      email: email,
    };
    console.log(obj);
    axiosemailcheck(obj);
  }
  return (
    <div className="call-to-action-main-area " id="share-demo">
      <div className="container">
        <div className="call-to-action-area theme-1">
          <div className="call-to-action-left">
            <h2>
              Get the best <br /> Solution
            </h2>
          </div>
          <div className="call-to-action-right">
            <form onSubmit={handlesubmit}>
            <div className="call-to-action-input-wrap">
              <input type="email" placeholder="maac@gmail.com" onChange={(e) => setemail(e.target.value)} required/>
              <button type="submit">Request for Demo</button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction1;
